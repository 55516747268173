import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import PlantList from './PlantList'

const Plants = () => {
    const [plantData, setPlantData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [adding, setAdding] = useState(false)
    const [newPlantName, setNewPlantName] = useState(null)

    useEffect (() => {
      getPlants()
    }, [])

    const getPlants = async () => {

        setErrorMsg('')
        setLoading(true)
        setPlantData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
        .get(`${process.env.REACT_APP_API_URL}/plant`,
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setPlantData(response.data.rows)
            setLoading(false)
            console.log('WHATS HERE: ', plantData)
        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const addNewClick = () => {
      setAdding(true)
    }

    const onCancelClick = () => {
      setNewPlantName(null)
      setAdding(false)
    }

    const savePlant = async (e) => {
      e.preventDefault()

      setErrorMsg('')
      setSaving(true)
      let session = await Auth.currentSession()
      let jwt = session.getIdToken().getJwtToken()
      axios
          .put(`${process.env.REACT_APP_API_URL}/plant`, {updateType: 'insert', plantName: newPlantName},
          {headers: {Authorization: jwt}}
          )
          .then(response => {
              setPlantData(response.data.rows.map(newPlantItem => {
                  return newPlantItem
              }))
              setSaving(false)
              setNewPlantName(null)
              setAdding(false)
          })
          .catch(error => {
              console.log('The error is: ', error)
              setErrorMsg('Problem saving data: ', error)
              setSaving(false)
          })            
    }

    return (
        <div className="report">
          <h2>Plants</h2>
          { loading && <h3>Loading...</h3>}
          {errorMsg && <p className="form__error">{errorMsg}</p>}
          {!adding && <button className="button" onClick={addNewClick} disabled={loading || adding}>Add New</button>}
          {adding && <div>
            <h4>New Plant:</h4>
            { saving && <h3>Saving...</h3>}
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Plant Name</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input 
                    type="text"
                    min="0"
                    className="text-input__customer-add"
                    name="plantName"
                    value={newPlantName}
                    disabled={saving}
                    onChange={e => setNewPlantName(e.target.value) } />
                  </td>
                  <td>
                    <button className="table-button" onClick={onCancelClick} disabled={saving}>Cancel</button>
                  </td>
                  <td>
                    <button className="table-button" onClick={savePlant} disabled={saving || !newPlantName} >Add</button>
                  </td>
                </tr>
              </tbody>
            </table>              
          </div>}
          {plantData.length > 0 && <div >
            <h4>Current Plants:</h4>
            <table className="styled-table styled-table__fixed">
              <thead>
                  <tr>
                      <th>Plant Name</th>
                      <th></th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                {plantData.map((plantItem) => (
                  <PlantList
                    key={plantItem.plant_id}
                    plantItem={plantItem}
                    setPlantData={setPlantData}
                    setErrorMsg={setErrorMsg}
                  />
                ))}
              </tbody>
            </table>
          </div>}
        </div>
    )
}

export default Plants