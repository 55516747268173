import SalesOrder from './sales/SalesOrder'
import SalesOrderReport from './sales/SalesOrderReport'

const SalesOrderOptions = [
    {
        title: 'Sales Order',
        path: 'sales-order',
        component: <SalesOrder />,
        visibility: 'show'
    },
    {
        title: 'Sales Order Report',
        path: 'sales-order-report',
        component: <SalesOrderReport />,
        visibility: 'show'
    },
    {
        title: 'Sales Order Edit',
        path: 'sales-order/:id',
        component: <SalesOrder />,
        visibility: 'hidden'
    }
]

export default SalesOrderOptions