import Customers from './admin-panel/Customers'
import Plants from './admin-panel/Plants'
import Species from './admin-panel/Species'
import Products from './admin-panel/Products'
import Brands from './admin-panel/Brands'
import Locations from './admin-panel/Locations'
import MscItems from './admin-panel/MscItems'

const AdministrativePanelOptions = [
    {
        title: 'Customers',
        path: 'customers',
        component: <Customers />,
        authorized: 'admin-group'
    },
    {
      title: 'Plants',
      path: 'plants',
      component: <Plants />,
      authorized: 'admin-group'
    },    
    {
      title: 'Species',
      path: 'species',
      component: <Species />,
      authorized: 'admin-group'
    }, 
    {
      title: 'Products',
      path: 'products',
      component: <Products />,
      authorized: 'admin-group'
    },   
    {
      title: 'Brands',
      path: 'brands',
      component: <Brands />,
      authorized: 'admin-group'
    }, 
    {
      title: 'Locations',
      path: 'locations',
      component: <Locations />,
      authorized: 'admin-group'
    }, 
    {
      title: 'MSC',
      path: 'msc',
      component: <MscItems />,
      authorized: 'admin-group'
    }, 
]

export default AdministrativePanelOptions