import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
const SpeciesList = ({speciesItem, setSpeciesData, setErrorMsg}) => {
  const [editCodeValue, setEditCodeValue] = useState(false)
  const [saving, setSaving] = useState(false)
  const [newSpeciesName, setNewSpeciesName] = useState(speciesItem.species_name)
  const [buttonLabel, setButtonLabel] = useState('Edit')

  useEffect(() => {
    setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
  }, [editCodeValue])

  const onEditClick = () => {
    setEditCodeValue(!editCodeValue)
    if (!editCodeValue) {
        setEditCodeValue(speciesItem.species_id)
    }
  }
  const saveSpecies = async (e) => {
    e.preventDefault()

    setSaving(true)
    setErrorMsg(null)
    let session = await Auth.currentSession()
    let jwt = session.getIdToken().getJwtToken()

    const updateType = 'update'
    axios
    .put(`${process.env.REACT_APP_API_URL}/species`, {speciesId: speciesItem.species_id, speciesName: newSpeciesName, updateType}, 
    {headers: {Authorization: jwt}}
    )
    .then(response => {
        setSaving(false)
        if (response.data.error) {
            setErrorMsg(response.data.error)
        } 
        onEditClick()
        setSpeciesData(response.data.rows)
    }) 
    .catch(error => {
        console.log('The error is: ', error)
        setErrorMsg('Problem saving data: ', error)
        setSaving(false)
    })                 
}

  return (
    <tr key={speciesItem.species_id}>
        <td>
          {!editCodeValue && speciesItem.species_name}
          {editCodeValue &&
            <input 
            type="text"
            min="0"
            className="text-input__customer-add"
            name="speciesName"
            value={newSpeciesName || ''}
            disabled={saving}
            onChange={e => setNewSpeciesName(e.target.value) } />
          }
        </td>
        <td>
          <button className="table-button__small" onClick={onEditClick} disabled={saving} >{buttonLabel}</button>
        </td>
        <td>
          <button className="table-button__small" onClick={saveSpecies} 
              disabled={saving || !editCodeValue || !newSpeciesName} >Save
          </button>
        </td>
    </tr>
  )
}

export default SpeciesList