import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import BrandList from './BrandList'

const Brands = () => {
    const [brandData, setBrandData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [adding, setAdding] = useState(false)
    const [newBrandName, setNewBrandName] = useState(null)
    const [newBrandCode, setNewBrandCode] = useState(null)

    useEffect (() => {
      getBrands()
    }, [])

    const getBrands = async () => {

        setErrorMsg('')
        setLoading(true)
        setBrandData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
        .get(`${process.env.REACT_APP_API_URL}/brand`,
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setBrandData(response.data.rows)
            setLoading(false)
            console.log('WHATS HERE: ', brandData)
        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const addNewClick = () => {
      setAdding(true)
    }

    const onCancelClick = () => {
      setNewBrandName(null)
      setAdding(false)
    }

    const saveBrand = async (e) => {
      e.preventDefault()

      setErrorMsg('')
      setSaving(true)
      let session = await Auth.currentSession()
      let jwt = session.getIdToken().getJwtToken()
      axios
          .put(`${process.env.REACT_APP_API_URL}/brand`, {updateType: 'insert', brandCode: newBrandCode, brandName: newBrandName},
          {headers: {Authorization: jwt}}
          )
          .then(response => {
              setBrandData(response.data.rows.map(newBrandItem => {
                  return newBrandItem
              }))
              setSaving(false)
              setNewBrandName(null)
              setAdding(false)
          })
          .catch(error => {
              console.log('The error is: ', error)
              setErrorMsg('Problem saving data: ', error)
              setSaving(false)
          })            
    }

    return (
        <div className="report">
          <h2>Brands</h2>
          { loading && <h3>Loading...</h3>}
          {errorMsg && <p className="form__error">{errorMsg}</p>}
          {!adding && <button className="button" onClick={addNewClick} disabled={loading || adding}>Add New</button>}
          {adding && <div>
            <h4>New Brand:</h4>
            { saving && <h3>Saving...</h3>}
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Brand Code</th>
                  <th>Brand Name</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  <input 
                    type="text"
                    min="0"
                    className="text-input__customer-add"
                    name="brandCode"
                    value={newBrandCode}
                    disabled={saving}
                    onChange={e => setNewBrandCode(e.target.value) } />                    
                  </td>
                  <td>
                    <input 
                    type="text"
                    min="0"
                    className="text-input__customer-add"
                    name="brandName"
                    value={newBrandName}
                    disabled={saving}
                    onChange={e => setNewBrandName(e.target.value) } />
                  </td>
                  <td>
                    <button className="table-button" onClick={onCancelClick} disabled={saving}>Cancel</button>
                  </td>
                  <td>
                    <button className="table-button" onClick={saveBrand} disabled={saving || !newBrandName} >Add</button>
                  </td>
                </tr>
              </tbody>
            </table>              
          </div>}
          {brandData.length > 0 && <div >
            <h4>Current Brands:</h4>
            <table className="styled-table styled-table__fixed">
              <thead>
                  <tr>
                      <th>Brand Code</th>
                      <th>Brand Name</th>
                      <th></th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                {brandData.map((brandItem) => (
                  <BrandList
                    key={brandItem.brand_id}
                    brandItem={brandItem}
                    setBrandData={setBrandData}
                    setErrorMsg={setErrorMsg}
                  />
                ))}
              </tbody>
            </table>
          </div>}
        </div>
    )
}

export default Brands