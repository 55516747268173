import React, { useState, useEffect, useRef } from 'react'

const SalesOrderItem = ({localIndex, salesItemNo, salesItem, salesValues, removeSalesItemRow,  
        calculateSalesOrderTotal, calculateSalesOrderTotalLbs, register, errors, setValue, getValues }) => {

    const [productList, setProductList] = useState([])
    const [prodWeight, setProdWeight] = useState()
    const [totalWeight, setTotalWeight] = useState()
    const [itemTotal, setItemTotal] = useState()
    const [weightType, setWeightType] = useState()

    useEffect(() => {
        recalcValues()
    }, [prodWeight, totalWeight, itemTotal, salesItem])

    const { ...speciesParams} = register(`salesItemData.${localIndex}.species`)
    const { ...prodParams} = register(`salesItemData.${localIndex}.product`)
    const { ...brandParams} = register(`salesItemData.${localIndex}.brandCode`)
    const { ...locParams} = register(`salesItemData.${localIndex}.location`)
    const { ...mscParams} = register(`salesItemData.${localIndex}.mscCode`)
    const { ...bolParams} = register(`salesItemData.${localIndex}.billOfLading`)
    const { ...lotParams} = register(`salesItemData.${localIndex}.lotNumber`)
    const { ...qtyParams} = register(`salesItemData.${localIndex}.quantity`)
    const {...varWeightParams} = register(`salesItemData.${localIndex}.productWeight`)
    const {...totWeightParams} = register(`salesItemData.${localIndex}.totalWeight`)
    const { ...priceParams} = register(`salesItemData.${localIndex}.salePrice`)
    const {...weightTypeParams} = register(`salesItemData.${localIndex}.weightType`)

    useEffect(() => {
        // load the filtered product list first
        let filterProductList = salesValues.productRows.filter(item => item.product_species_id == salesItem.species)
        setProductList(filterProductList)

        // set the values in the register
        setValue(`salesItemData.${localIndex}.species`, salesItem.species)
        setValue(`salesItemData.${localIndex}.brandCode`, salesItem.brandCode)
        setValue(`salesItemData.${localIndex}.location`, salesItem.location)
        setValue(`salesItemData.${localIndex}.mscCode`, salesItem.mscCode)
        setValue(`salesItemData.${localIndex}.billOfLading`, salesItem.billOfLading)
        setValue(`salesItemData.${localIndex}.lotNumber`, salesItem.lotNumber)
        setValue(`salesItemData.${localIndex}.quantity`, salesItem.quantity)
        setValue(`salesItemData.${localIndex}.productWeight`, salesItem.productWeight)
        setValue(`salesItemData.${localIndex}.totalWeight`, salesItem.totalWeight)
        setValue(`salesItemData.${localIndex}.salePrice`, salesItem.salePrice)
        setTimeout(() => {
            setValue(`salesItemData.${localIndex}.product`, salesItem.productType)
            const prodWeightInfo = getProductWeight(salesItem.productType)
            if (prodWeightInfo.weightType === 'FIX') {
                setProdWeight(prodWeightInfo.productWeight)
            } else {
                setProdWeight(salesItem.productWeight)
            }
            setWeightType(prodWeightInfo.weightType)
            setValue(`salesItemData.${localIndex}.weightType`, prodWeightInfo.weightType)
            if (prodWeightInfo.weightType === 'BLK') {
                setTotalWeight(salesItem.totalWeight)
            }

            // recalcValues()
        }, 250)
    }, [])

    const onSpeciesChange = (e) => {
        let filterProductList = []
        if (e.target.value !== '' && salesValues.productRows) {
            filterProductList = salesValues.productRows.filter(item => item.product_species_id == e.target.value)
        } 
        setProductList(filterProductList)
        setValue(`salesItemData.${localIndex}.product`, null)
        setProdWeight(null)
        setWeightType(null)
        setValue(`salesItemData.${localIndex}.weightType`, null)
        recalcValues()
    }

    const onProductChange = (e) => {
        let prodWeightInfo = getProductWeight(e.target.value)
        salesItem.productWeight = prodWeightInfo.productWeight
        salesItem.weightType = prodWeightInfo.weightType  
        setValue(`salesItemData.${localIndex}.weightType`, prodWeightInfo.weightType)
        if (weightType !== 'BLK' && prodWeightInfo.weightType === 'BLK') {
            setValue(`salesItemData.${localIndex}.quantity`)
        }
        setValue(`salesItemData.${localIndex}.product`, e.target.value)
        setValue(`salesItemData.${localIndex}.productWeight`, null)
        setValue(`salesItemData.${localIndex}.totalWeight`, null)
        setProdWeight(prodWeightInfo.productWeight)
        setWeightType(prodWeightInfo.weightType)
        setValue(`salesItemData.${localIndex}.weightType`, prodWeightInfo.weightType)
        recalcValues()
    }

    const onQuantityChange = (e) => {
        setValue(`salesItemData.${localIndex}.quantity`, e.target.value)
        recalcValues()
    }

    const onWeightChange = (e) => {
        setValue(`salesItemData.${localIndex}.productWeight`, e.target.value)
        setProdWeight(e.target.value)
        recalcValues()
    }

    const onTotWeightChange = (e) => {
        setValue(`salesItemData.${localIndex}.totalWeight`, e.target.value)
        setTotalWeight(e.target.value)
        salesItem.totalWeight = Number(e.target.value)
        recalcValues()
    }

    const onPriceChange = (e) => {
        setValue(`salesItemData.${localIndex}.salePrice`, e.target.value)
        recalcValues()
    }

    const recalcValues = () => {
        if (weightType !== 'BLK') {
            setTotalWeight(calculateTotalWeight())
        }
        setItemTotal(calculateTotalPrice())
        calculateSalesOrderTotal()
        calculateSalesOrderTotalLbs()
    }

    // function to get product weight 
    const getProductWeight = (productId) => {
        let productWeight = null
        let weightType = null
        let productItem = salesValues.productRows.filter(item => item.product_id == productId)
        if (productItem.length) {
            productWeight = productItem[0].product_weight
            weightType = productItem[0].weight_type
        }
        return {productWeight, weightType}
    }

    const calculateTotalWeight = () => {
        let totalWeight = null
        const values = getValues(`salesItemData.${localIndex}`)
        if (values.quantity && prodWeight) {
            totalWeight = values.quantity * prodWeight
        }
        
        if (weightType) {
            salesItem.totalWeight = totalWeight
        }
        return totalWeight
    }

    const calculateTotalPrice = () => {
        let newTotal = null
        const values = getValues(`salesItemData.${localIndex}`)
        if (values.salePrice && totalWeight) {
            newTotal = values.salePrice * totalWeight
            salesItem.itemTotal = newTotal
        }
        return newTotal
    }

    let [speciesErrorInd, prodErrorInd, brandErrorInd, locErrorInd, qtyErrorInd, weightErrorInd, totWeightErrorInd, priceErrorInd] = [false, false, false, false, false, false, false]

    if (errors && errors.salesItemData) {
        if (errors.salesItemData.indexOf(salesItemNo)) {
            const error = errors.salesItemData[salesItemNo]
            if (error) {
                if (error.species) {
                    speciesErrorInd = true
                }
                if (error.product) {
                    prodErrorInd = true
                }
                if (error.brandCode) {
                    brandErrorInd = true
                }
                if (error.location) {
                    locErrorInd = true
                }
                if (error.quantity) {
                    qtyErrorInd = true
                }
                if (error.productWeight) {
                    weightErrorInd = true
                }
                if (error.totalWeight) {
                    totWeightErrorInd = true
                }                
                if (error.salePrice) {
                    priceErrorInd = true
                }
            }
        }
    }

    const removeRow = async () => {
        await removeSalesItemRow(salesItemNo, localIndex)
    }

    return (
        <tr>
            <td>
                <select id="species"
                {...speciesParams} 
                className={"species__select" + (speciesErrorInd? '_error' : '')} 
                onChange={(e) => onSpeciesChange(e, salesItemNo)}>
                    <option key="select" value="">Please Select</option>
                    {salesValues.speciesRows && salesValues.speciesRows.map((speciesRow) => (
                        <option key={speciesRow.species_id} value={speciesRow.species_id}>{speciesRow.species_name}</option>
                    ))}
                </select>
            </td>
            <td>
                <select id="productType" 
                {...prodParams} 
                className={"product__select" + (prodErrorInd? '_error' : '')} 
                onChange={e => onProductChange(e)}>
                    <option key="select" value="">Please Select</option>
                    {productList && productList.map((productRow) => (
                        <option key={productRow.product_id} value={productRow.product_id}>{productRow.product_name}</option>
                    ))}
                </select>
                {weightType && <input type="hidden" {...weightTypeParams} id="weightType"  /> }
            </td>
            <td>
                <select id="brandCode"
                {...brandParams} 
                className={"brand__select" + (brandErrorInd? '_error' : '')} >
                    <option key="select" value="">Please Select</option>
                    {salesValues.brandRows && salesValues.brandRows.map((brandRow) => (
                            <option key={brandRow.brand_code} value={brandRow.brand_code}>{brandRow.brand_name}</option>
                        ))}
                </select>
            </td>
            <td>
                <select id="location"
                {...locParams} 
                className={"location__select" + (locErrorInd? '_error' : '')}>
                    <option key="select" value="">Please Select</option>
                    {salesValues.prodLocationRows && salesValues.prodLocationRows.map((locationRow) => (
                        <option key={locationRow.location_id} value={locationRow.location_id}>{locationRow.location_name}</option>
                    ))}
                </select>
            </td>
            <td>
                <select id="mscCode"
                {...mscParams} 
                className="msc__select">
                    <option key="select" value="">None</option>
                    {salesValues.mscRows && salesValues.mscRows.map((mscRow) => (
                        <option key={mscRow.msc_id} value={mscRow.msc_id}>{mscRow.msc_code} ({mscRow.plant_code})</option>
                    ))}
                </select>
            </td>
            <td>
                <input id="billOfLading"
                    {...bolParams}
                    type="text"
                    className="text-input__counter"
                />
            </td>
            <td>
                <input id="lotNumber"
                    {...lotParams}
                    type="text"
                    className="text-input__counter"
                />
            </td>
            <td>
                {weightType === 'BLK' && '-'}
                {weightType !== 'BLK' &&
                    <input id="quantity"
                        {...qtyParams}
                        type="text"
                        className={"text-input__counter" + (qtyErrorInd? '_error' : '')}
                        onChange={e => onQuantityChange(e)}
                    />
                    }
            </td>
            <td>
                {weightType === 'FIX' && prodWeight}
                {weightType === 'BLK' && '-'}
                {weightType === 'VAR' &&
                    <input id="productWeight"
                        {...varWeightParams}
                        type="number"
                        step="0.01"
                        min="0.01"
                        className={"text-input__counter" + (weightErrorInd? '_error' : '')}
                        onChange={e => onWeightChange(e)}
                    />                
                }
            </td>
            <td>
                {weightType !== 'BLK' && totalWeight && totalWeight.toLocaleString('en-US')}
                {weightType === 'BLK' &&
                    <input id="totalWeight"
                        {...totWeightParams}
                        type="number"
                        step="0.01"
                        min="0.01"
                        className={"text-input__counter" + (totWeightErrorInd? '_error' : '')}
                        onChange={e => onTotWeightChange(e)}
                    />                    
                }
            </td>
            <td>
                <input id="salePrice"
                    {...priceParams}
                    type="text"
                    // step="0.01"
                    className={"text-input__counter" + (priceErrorInd? '_error' : '')}
                    onChange={e => onPriceChange(e)}
                />
            </td>
            <td>
                {itemTotal && Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(itemTotal) }
            </td>
            <td>
                <button type="button" className="table-button__small" disabled={!salesItemNo} onClick={removeRow} >x</button>
            </td>
        </tr>
    )
}

export default SalesOrderItem