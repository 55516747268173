import moment from 'moment'
import React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

export default class SalesDate extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            calendarFocused: false
        }
    }

    onDateChange = (salesDate) => {
        if (salesDate) {
            this.props.updateSalesDate(salesDate)
        }
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused}))
    }
    isOutsideRange = (day) => {
        // return day.isAfter(moment())
        return false
    }

    render() {
        return (
            <div className="reportdate">
                <div className="reportdate__title">
                    <label className="style_block">{this.props.label}:</label>
                <SingleDatePicker
                            date={this.props.salesDate}
                            onDateChange={this.onDateChange}
                            focused={this.state.calendarFocused}
                            onFocusChange={this.onFocusChange}
                            numberOfMonths={1}
                            isOutsideRange={this.isOutsideRange}
                            showDefaultInputIcon inputIconPosition="after"
                            small
                />
                </div>
            </div>
        )
    }
}