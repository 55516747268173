import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import MscItemList from './MscItemList'

const MscItems = () => {
    const [mscItemData, setMscItemData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [adding, setAdding] = useState(false)
    const [newMscCode, setNewMscCode] = useState(null)
    const [newMscPlantCode, setNewMscPlantCode] = useState(null)

    useEffect (() => {
      getMscItems()
    }, [])

    const getMscItems = async () => {

        setErrorMsg('')
        setLoading(true)
        setMscItemData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
        .get(`${process.env.REACT_APP_API_URL}/msccode`,
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setMscItemData(response.data.rows)
            setLoading(false)
            console.log('WHATS HERE: ', mscItemData)
        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const addNewClick = () => {
      setAdding(true)
    }

    const onCancelClick = () => {
      setNewMscCode(null)
      setNewMscPlantCode(null)
      setAdding(false)
    }

    const saveMscItem = async (e) => {
      e.preventDefault()

      setErrorMsg('')
      setSaving(true)
      let session = await Auth.currentSession()
      let jwt = session.getIdToken().getJwtToken()
      axios
          .put(`${process.env.REACT_APP_API_URL}/msccode`, {updateType: 'insert', mscCode: newMscCode, plantCode: newMscPlantCode},
          {headers: {Authorization: jwt}}
          )
          .then(response => {
              setMscItemData(response.data.rows.map(newMscItem => {
                  return newMscItem
              }))
              setSaving(false)
              setNewMscCode(null)
              setNewMscPlantCode(null)
              setAdding(false)
          })
          .catch(error => {
              console.log('The error is: ', error)
              setErrorMsg('Problem saving data: ', error)
              setSaving(false)
          })            
    }

    return (
        <div className="report">
          <h2>MSC Codes</h2>
          { loading && <h3>Loading...</h3>}
          {errorMsg && <p className="form__error">{errorMsg}</p>}
          {!adding && <button className="button" onClick={addNewClick} disabled={loading || adding}>Add New</button>}
          {adding && <div>
            <h4>New MSC Code:</h4>
            { saving && <h3>Saving...</h3>}
            <table className="styled-table">
              <thead>
                <tr>
                  <th>MSC Code</th>
                  <th>Plant Code</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input 
                    type="text"
                    min="0"
                    className="text-input__customer-add"
                    name="mscCode"
                    value={newMscCode}
                    disabled={saving}
                    onChange={e => setNewMscCode(e.target.value) } />
                  </td>
                  <td>
                    <input 
                    type="text"
                    min="0"
                    className="text-input__customer-add"
                    name="plantCode"
                    value={newMscPlantCode}
                    disabled={saving}
                    onChange={e => setNewMscPlantCode(e.target.value) } />
                  </td>                  
                  <td>
                    <button className="table-button" onClick={onCancelClick} disabled={saving}>Cancel</button>
                  </td>
                  <td>
                    <button className="table-button" onClick={saveMscItem} disabled={saving || !newMscCode || !newMscPlantCode} >Add</button>
                  </td>
                </tr>
              </tbody>
            </table>              
          </div>}
          {mscItemData.length > 0 && <div >
            <h4>Current MSC Codes:</h4>
            <table className="styled-table styled-table__fixed">
              <thead>
                  <tr>
                      <th>MSC Code</th>
                      <th>Plant Code</th>
                      <th></th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                {mscItemData.map((mscItem) => (
                  <MscItemList
                    key={mscItem.msc_id}
                    mscItem={mscItem}
                    setMscItemData={setMscItemData}
                    setErrorMsg={setErrorMsg}
                  />
                ))}
              </tbody>
            </table>
          </div>}
        </div>
    )
}

export default MscItems