import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
const LocationList = ({locationItem, setLocationData, setErrorMsg}) => {
  const [editCodeValue, setEditCodeValue] = useState(false)
  const [saving, setSaving] = useState(false)
  const [newLocationName, setNewLocationName] = useState(locationItem.location_name)
  const [newLocationType, setNewLocationType] = useState(locationItem.location_type)
  const [buttonLabel, setButtonLabel] = useState('Edit')

  useEffect(() => {
    setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
  }, [editCodeValue])

  const onEditClick = () => {
    setEditCodeValue(!editCodeValue)
    if (!editCodeValue) {
        setEditCodeValue(locationItem.location_id)
    }
  }
  const saveLocation = async (e) => {
    e.preventDefault()

    setSaving(true)
    setErrorMsg(null)
    let session = await Auth.currentSession()
    let jwt = session.getIdToken().getJwtToken()

    const updateType = 'update'
    axios
    .put(`${process.env.REACT_APP_API_URL}/location`, {locationId: locationItem.location_id, locationName: newLocationName, locationType: newLocationType, updateType}, 
    {headers: {Authorization: jwt}}
    )
    .then(response => {
        setSaving(false)
        if (response.data.error) {
            setErrorMsg(response.data.error)
        } 
        onEditClick()
        setLocationData(response.data.rows)
    }) 
    .catch(error => {
        console.log('The error is: ', error)
        setErrorMsg('Problem saving data: ', error)
        setSaving(false)
    })                 
}

  return (
    <tr key={locationItem.location_id}>
        <td>
          {!editCodeValue && locationItem.location_name}
          {editCodeValue &&
            <input 
            type="text"
            min="0"
            className="text-input__customer-add"
            name="locationName"
            value={newLocationName || ''}
            disabled={saving}
            onChange={e => setNewLocationName(e.target.value) } />
          }
        </td>
        <td>
          {!editCodeValue && (locationItem.location_type === 1 && 'Plant')}
          {!editCodeValue && (locationItem.location_type === 2 && 'Storage Facility')}
          {editCodeValue &&
            <select className="msc__select text-input__margin" value={newLocationType} id="locationType" onChange={e => setNewLocationType(e.target.value)}>
              <option key='1' value='1'>Plant</option>
              <option key='2' value='2'>Storage Facility</option>
            </select>
          }
        </td>        
        <td>
          <button className="table-button__small" onClick={onEditClick} disabled={saving} >{buttonLabel}</button>
        </td>
        <td>
          <button className="table-button__small" onClick={saveLocation} 
              disabled={saving || !editCodeValue || !newLocationName} >Save
          </button>
        </td>
    </tr>
  )
}

export default LocationList