import WelcomePage from "./WelcomePage"

const WelcomePanelOptions = [
    {
        title: 'Welcome Page',
        path: '/',
        component: <WelcomePage />
    }
]

export default WelcomePanelOptions