import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
const CustomerList = ({customerItem, setCustomerData, setErrorMsg}) => {
  const [editCodeValue, setEditCodeValue] = useState(false)
  const [saving, setSaving] = useState(false)
  const [newCustomerName, setNewCustomerName] = useState(customerItem.customer_name)
  const [newCustomerType, setNewCustomerType] = useState(customerItem.customer_type)
  const [buttonLabel, setButtonLabel] = useState('Edit')

  useEffect(() => {
    setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
  }, [editCodeValue])

  const onEditClick = () => {
    setEditCodeValue(!editCodeValue)
    if (!editCodeValue) {
        setEditCodeValue(customerItem.customer_id)
    }
  }
  const saveCustomer = async (e) => {
    e.preventDefault()

    setSaving(true)
    setErrorMsg(null)
    let session = await Auth.currentSession()
    let jwt = session.getIdToken().getJwtToken()

    const updateType = 'update'
    axios
    .put(`${process.env.REACT_APP_API_URL}/customer`, {customerId: customerItem.customer_id, customerName: newCustomerName, customerType: newCustomerType, updateType}, 
    {headers: {Authorization: jwt}}
    )
    .then(response => {
        setSaving(false)
        if (response.data.error) {
            setErrorMsg(response.data.error)
        } 
        onEditClick()
        setCustomerData(response.data.rows)
    }) 
    .catch(error => {
        console.log('The error is: ', error)
        setErrorMsg('Problem saving data: ', error)
        setSaving(false)
    })                 
}

  return (
    <tr key={customerItem.customer_id}>
        <td>
          {!editCodeValue && customerItem.customer_name}
          {editCodeValue &&
            <input 
            type="text"
            min="0"
            className="text-input__customer-add-large"
            name="customerName"
            value={newCustomerName || ''}
            disabled={saving}
            onChange={e => setNewCustomerName(e.target.value) } />
          }
        </td>
        <td>
          {!editCodeValue && (customerItem.customer_type === 1 && 'External')}
          {!editCodeValue && (customerItem.customer_type === 2 && 'Internal')}
          {editCodeValue &&
            <select className="msc__select text-input__margin" value={newCustomerType} id="customerType" onChange={e => setNewCustomerType(e.target.value)}>
              <option key='1' value='1'>External</option>
              <option key='2' value='2'>Internal</option>
            </select>
          }
        </td>            
        <td>
          <button className="table-button__small" onClick={onEditClick} disabled={saving} >{buttonLabel}</button>
        </td>
        <td>
          <button className="table-button__small" onClick={saveCustomer} 
              disabled={saving || !editCodeValue || !newCustomerName} >Save
          </button>
        </td>
    </tr>
  )
}

export default CustomerList