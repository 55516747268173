import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
const ProductList = ({productItem, speciesValues, setProductData, setErrorMsg}) => {
  const [editCodeValue, setEditCodeValue] = useState(false)
  const [saving, setSaving] = useState(false)
  const [newProductName, setNewProductName] = useState(productItem.product_name)
  const [buttonLabel, setButtonLabel] = useState('Edit')
  const [productSpeciesName, setProductSpeciesName] = useState(productItem.species_name)
  const [productSpeciesId, setProductSpeciesId] = useState(productItem.species_id)
  const [productWeight, setProductWeight] = useState(productItem.product_weight)
  const [weightType, setWeightType] = useState(productItem.weight_type)

  useEffect(() => {
    setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
  }, [editCodeValue])

  const onEditClick = () => {
    setEditCodeValue(!editCodeValue)
    if (!editCodeValue) {
        setEditCodeValue(productItem.product_id)
    }
  }
//   const saveProduct = async (e) => {
//     e.preventDefault()

//     setSaving(true)
//     setErrorMsg(null)
//     let session = await Auth.currentSession()
//     let jwt = session.getIdToken().getJwtToken()

//     const updateType = 'update'
//     axios
//     .put(`${process.env.REACT_APP_API_URL}/product`, {productId: productItem.product_id, productName: newProductName, updateType}, 
//     {headers: {Authorization: jwt}}
//     )
//     .then(response => {
//         setSaving(false)
//         if (response.data.error) {
//             setErrorMsg(response.data.error)
//         } 
//         onEditClick()
//         setProductData(response.data.rows)
//     }) 
//     .catch(error => {
//         console.log('The error is: ', error)
//         setErrorMsg('Problem saving data: ', error)
//         setSaving(false)
//     })                 
// }

  return (
    <tr key={productItem.product_id}>
        <td>
          {!editCodeValue && productItem.species_name}
          {/* {editCodeValue &&
            <select className="msc__select text-input__margin" id="species" value={productSpeciesId} onChange={e => setProductSpeciesId(e.target.value)}>
              {speciesValues && speciesValues.map((speciesRow) => (
                  <option key={speciesRow.species_id} value={speciesRow.species_id}>{speciesRow.species_name}</option>
              ))}
            </select>          
          } */}
        </td>
        <td>
          {!editCodeValue && productItem.product_name}
          {/* {editCodeValue &&
            <input 
              type="text"
              min="0"
              className="text-input__customer-add"
              name="productName"
              value={newProductName || ''}
              disabled={saving}
              onChange={e => setNewProductName(e.target.value) } />
          } */}
        </td>
        <td>
          {!editCodeValue && (productItem.weight_type === 'FIX' && 'Fixed')}
          {!editCodeValue && (productItem.weight_type === 'VAR' && 'Variable')}
          {!editCodeValue && (productItem.weight_type === 'BLK' && 'Bulk')}
          {/* {editCodeValue &&
            <select className="msc__select text-input__margin" value={weightType} id="weightType" onChange={e => setWeightType(e.target.value)}>
              <option key='FIX' value='FIX'>Fixed</option>
              <option key='VAR' value='VAR'>Variable</option>
              <option key='BLK' value='BLK'>Bulk</option>
            </select>          
          } */}
        </td>
        <td>
          {!editCodeValue && productItem.product_weight}
          {/* {editCodeValue &&
            <input 
              type="number"
              min="0"
              className="text-input__counter"
              name="productWeight"
              value={productWeight}
              disabled={saving || weightType === 'VAR' || weightType === 'BLK'}
              onChange={e => setProductWeight(e.target.value) } />          
          } */}
        </td>
        {/* <td>
          <button className="table-button__small" onClick={onEditClick} disabled={saving} >{buttonLabel}</button>
        </td>
        <td>
          <button className="table-button__small" onClick={saveProduct} 
              disabled={saving || !editCodeValue || !newProductName || !productSpeciesId || (weightType === 'FIX' && !productWeight)} >Save
          </button>
        </td> */}
    </tr>
  )
}

export default ProductList