import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import LocationList from './LocationList'

const Locations = () => {
    const [locationData, setLocationData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [adding, setAdding] = useState(false)
    const [newLocationName, setNewLocationName] = useState(null)
    const [newLocationType, setNewLocationType] = useState(null)

    useEffect (() => {
      getLocations()
    }, [])

    const getLocations = async () => {

        setErrorMsg('')
        setLoading(true)
        setLocationData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
        .get(`${process.env.REACT_APP_API_URL}/location`,
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setLocationData(response.data.rows)
            setLoading(false)
            console.log('WHATS HERE: ', locationData)
        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const addNewClick = () => {
      setAdding(true)
      setNewLocationType(1)
    }

    const onCancelClick = () => {
      setNewLocationName(null)
      setAdding(false)
    }

    const saveLocation = async (e) => {
      e.preventDefault()

      setErrorMsg('')
      setSaving(true)
      let session = await Auth.currentSession()
      let jwt = session.getIdToken().getJwtToken()
      axios
          .put(`${process.env.REACT_APP_API_URL}/location`, {updateType: 'insert', locationName: newLocationName, locationType: newLocationType},
          {headers: {Authorization: jwt}}
          )
          .then(response => {
              setLocationData(response.data.rows.map(newLocationItem => {
                  return newLocationItem
              }))
              setSaving(false)
              setNewLocationName(null)
              setAdding(false)
          })
          .catch(error => {
              console.log('The error is: ', error)
              setErrorMsg('Problem saving data: ', error)
              setSaving(false)
          })            
    }

    return (
        <div className="report">
          <h2>Locations</h2>
          { loading && <h3>Loading...</h3>}
          {errorMsg && <p className="form__error">{errorMsg}</p>}
          {!adding && <button className="button" onClick={addNewClick} disabled={loading || adding}>Add New</button>}
          {adding && <div>
            <h4>New Location:</h4>
            { saving && <h3>Saving...</h3>}
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Location Name</th>
                  <th>Location Type</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input 
                    type="text"
                    min="0"
                    className="text-input__customer-add"
                    name="locationName"
                    value={newLocationName}
                    disabled={saving}
                    onChange={e => setNewLocationName(e.target.value) } />
                  </td>
                  <td>
                    <select className="msc__select text-input__margin" id="locationType" onChange={e => setNewLocationType(e.target.value)}>
                      <option key='1' value='1'>Plant</option>
                      <option key='2' value='2'>Storage Facility</option>
                    </select>
                  </td>
                  <td>
                    <button className="table-button" onClick={onCancelClick} disabled={saving}>Cancel</button>
                  </td>
                  <td>
                    <button className="table-button" onClick={saveLocation} disabled={saving || !newLocationName} >Add</button>
                  </td>
                </tr>
              </tbody>
            </table>              
          </div>}
          {locationData.length > 0 && <div >
            <h4>Current Locations:</h4>
            <table className="styled-table styled-table__fixed">
              <thead>
                  <tr>
                      <th>Location Name</th>
                      <th>Location Type</th>
                      <th></th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                {locationData.map((locationItem) => (
                  <LocationList
                    key={locationItem.location_id}
                    locationItem={locationItem}
                    setLocationData={setLocationData}
                    setErrorMsg={setErrorMsg}
                  />
                ))}
              </tbody>
            </table>
          </div>}
        </div>
    )
}

export default Locations